import App from '@components/login/App.vue'
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueCookies from 'vue-cookies';

generateApplication(
  App,
  "#app",
  {
    injections: [{ name: '$axios', module: generateAxiosInstance(hostApiKey, '/api/v1/admin/') }],
    packages: [VueSweetalert2, VueCookies],
  }
)